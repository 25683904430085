@tailwind base;
@tailwind components;
@tailwind utilities;
.mosquito-image {
    transition: transform 0.3s ease-in-out;
    border-radius: 10px;
    border-width: 6px;
    border-style: solid;
    border-color: black;
}

.mosquito-image:hover {
    transform: scale(1.2);
}

.image-slider img {
    width: 100%;
    height: auto;
    object-fit: cover;
    /* This can be adjusted based on how you want to handle aspect ratio */
}

.App {
    font-family: Josefin Sans, sans-serif;
}

.title {
    color: hsl(0, 0%, 28%);
    font-size: 50px !important;
    font-weight: bold !important;
    font-family: monospace;
    letter-spacing: 7px !important;
    cursor: pointer;
    text-transform: uppercase;
}

.title {
    padding: 64px;
    background: linear-gradient( to right, hsl(0, 0%, 30%) 0, hsl(0, 0%, 100%) 10%, hsl(0, 0%, 30%) 20%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 3s infinite linear;
}

@keyframes shine {
    0% {
        background-position: 0;
    }
    60% {
        background-position: 600px;
    }
    100% {
        background-position: 600px;
    }
}

.TracingBeam>div {
    margin-bottom: 0;
    padding-bottom: 0;
}


/* Ensure the Contributions component has no bottom margin or padding */

.Contributions {
    margin-bottom: 0;
    padding-bottom: 0;
}

.body-wrapper {
    margin-left: 30px;
    margin-right: auto;
    margin-top: 30px;
    font-family: Arial, sans-serif;
}

#label-studio {
    position: relative;
    margin-top: 20px;
    text-align: left;
    font-family: Josefin Sans;
    z-index: 0;
}


/* App.css */

.scale-hover:hover {
    transform: scale(1.1);
    transition: transform 0.5s ease-in-out;
    /* Smooth transition for scaling */
}

.boxFont {
    font-size: 55px;
    color: black;
    border: 1px solid black;
    width: 5rem;
    padding: 10px;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    background-color: white;
    border-radius: 10%;
}

.boxFont2 {
    font-size: 35px;
    color: black;
    border: 1px solid black;
    width: 4rem;
    padding: 8px;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    background-color: white;
}

.boxLottie {
    font-size: 55px;
    color: black;
    border: 1px solid black;
    width: 5rem;
    /* Added 'px' for width */
    display: flex;
    height: 5rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: black;
    border-radius: 10px;
    /* Added 'px' for border-radius */
}

.boxLottie2 {
    font-size: 35px;
    color: black;
    border: 1px solid black;
    width: 4rem;
    /* Added 'px' for width */
    display: flex;
    height: 4rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: black;
    border-radius: 10px;
    /* Added 'px' for border-radius */
}

.navbar {
    position: -webkit-sticky;
    /* For Safari */
    position: sticky;
    top: 0;
    z-index: 1000;
    /* Ensure it's above other content */
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.label-area {
    display: flex;
    align-items: center;
}

.RectangleLabels {
    flex-grow: 1;
    margin-right: 20px;
}

.explanation {
    width: 200px;
}

.dashboard-header {
    margin-left: 30px;
}

.dashboard-header h1 {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
}

.dashboard-header h5 {
    font-size: 1.5rem;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 2rem;
}

.dashboard-header-button {
    font-size: 1.2rem;
    background-color: #234f87;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 5px;
}

.dashboard-header-button:hover {
    background-color: #192756;
}

.previous {
    background-color: #1c8777;
}

.previous:hover {
    background-color: #4dc9a6;
}


/* styles.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modal-content {
    background-color: white;
    padding: 20px;
}

.modal-content img {
    max-width: 100%;
    height: auto;
}

.animation-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    animation-name: fadeOut;
    animation-duration: 3s;
}

@keyframes fadeOut {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.alert-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.alert-modal h2 {
    margin-bottom: 20px;
}

.alert-modal button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.modal2-content {
    background-color: white;
    border-radius: 30px;
    padding: 30px;
}

.alert-modal button:hover {
    background-color: #0056b3;
}

.contextButton {
    margin-left: 0px;
    font-size: 16px;
    display: block;
    padding: 10px 10px;
    background-color: #20717a;
    color: white;
    border: none;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: transform 0.3s;
}

.contextButton:hover {
    transform: scale(1.1);
}


/* App.module.css */

.addButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    background-color: #335880;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: larger;
    padding: 10px 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.deleteButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-right: 2px;
    background-color: #6f1944;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: larger;
    padding: 10px 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.deleteButton:hover {
    background-color: #c81e1e;
}

.addButton:hover {
    background-color: #0056b3;
}

.inputField {
    display: none;
    margin: 0px 0px;
    margin-right: 5px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ced4da;
    transition: border 0.3s;
}

.inputField:focus {
    border-color: #007bff;
}

.showInput {
    display: block;
}

.navbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

.navbar-logo img {
    height: 60px;
    width: auto;
}

.navbar-links {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.navbar-links button {
    margin: 5px 10px;
}

@media (max-width: 768px) {
    .navbar {
        flex-direction: column;
        align-items: flex-start;
    }
    .navbar-links {
        flex-direction: column;
        width: 100%;
    }
    .navbar-links button {
        width: 100%;
        text-align: left;
        padding: 10px 0;
    }
}


/* styles.css */

@media (max-width: 768px) {
    .patent-card {
        flex: 1 1 calc(60% - 40px);
    }
}

@media (max-width: 480px) {
    .patent-card {
        flex: 1 1 100%;
    }
}

.demo-buttons {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.demo-button {
    padding: 20px;
    border-radius: 10px;
    cursor: pointer;
    text-align: left;
    flex: 1 1 calc(33% - 20px);
    min-width: 150px;
    max-width: 300px;
}

@media (max-width: 768px) {
    .demo-buttons {
        flex-direction: column;
    }
    .demo-button {
        flex: 1 1 100%;
        min-width: auto;
        max-width: none;
    }
}


/* styles.css */


/* Patents Section Styles */

.patents-section {
    background: linear-gradient(90deg, #a2b6d1, #fff, #fff, #eae5f9);
    color: #333;
    padding: 50px 20px;
    font-family: "Plus Jakarta Sans", sans-serif;
    text-align: center;
}

.patents-section h2 {
    font-size: 36px;
    margin-bottom: 20px;
}

.patents-section p {
    font-size: 20px;
    margin: 0 auto 40px;
    max-width: 800px;
}

.patent-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}


/* Patent Card Styles */

.patent-card {
    background-color: #212121;
    border-radius: 10px;
    padding: 20px;
    width: calc(33.333% - 40px);
    /* Three cards per row minus gaps */
    max-width: 300px;
    box-sizing: border-box;
    cursor: pointer;
    color: white;
    text-align: center;
    transition: transform 0.3s;
}

.patent-card:hover {
    transform: scale(1.02);
}

.patent-card img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
    margin-bottom: 10px;
    transition: transform 0.3s;
}

.patent-card img:hover {
    transform: scale(1.1);
}

.patent-card h5 {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: bold;
}

.patent-card p {
    font-size: 16px;
    color: white;
}


/* Media Queries for Responsiveness */

@media (max-width: 1024px) {
    .patent-card {
        width: calc(50% - 40px);
        /* Two cards per row */
    }
}

@media (max-width: 768px) {
    .patent-card {
        width: 100%;
        /* Full width on mobile */
    }
}

.team-section {
    background: linear-gradient(90deg, #a2b6d1, #fff, #fff, #eae5f9);
    padding: 40px 20px;
    text-align: center;
    font-family: "Plus Jakarta Sans", sans-serif;
}

.team-section h2 {
    color: black;
    font-size: 36px;
}

.team-section p {
    color: black;
    font-size: 16px;
    margin-bottom: 40px;
}

.team-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}


/* Team Card Styles */

.team-card {
    background-color: #353333;
    border-radius: 20px;
    overflow: hidden;
    text-align: center;
    flex-shrink: 0;
    width: 200px;
    /* Fixed width to maintain image size */
    margin: 10px;
    cursor: pointer;
    position: relative;
    transition: transform 0.2s;
}

.team-card:hover {
    transform: scale(1.05);
}

.team-card img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.team-card-info {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 10px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
}

.team-card-info h3 {
    margin: 0;
    font-size: 14px;
    color: #fff;
}

.team-card-info p {
    margin: 0;
    font-size: 12px;
    color: #ddd;
}


/* Media Queries for Responsiveness */

@media (max-width: 768px) {
    .team-container {
        justify-content: center;
    }
}


/* Navbar Styles */

.navbar {
    position: sticky;
    top: 0;
    z-index: 1000;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
}

.navbar-logo img {
    height: 60px;
    width: auto;
}

.navbar-links {
    display: flex;
    align-items: center;
}

.navbar-links button {
    margin: 0 10px;
}

.navbar-menu-icon {
    display: none;
    cursor: pointer;
}

.menu-icon {
    width: 25px;
    height: 20px;
    position: relative;
}

.menu-icon span {
    background-color: black;
    position: absolute;
    left: 0;
    right: 0;
    height: 3px;
    transition: all 0.3s ease-in-out;
}

.menu-icon span:nth-child(1) {
    top: 0;
}

.menu-icon span:nth-child(2) {
    top: 8px;
}

.menu-icon span:nth-child(3) {
    top: 16px;
}

.menu-icon.open span:nth-child(1) {
    transform: rotate(45deg);
    top: 8px;
}

.menu-icon.open span:nth-child(2) {
    opacity: 0;
}

.menu-icon.open span:nth-child(3) {
    transform: rotate(-45deg);
    top: 8px;
}


/* Mobile Styles */

@media (max-width: 768px) {
    .navbar {
        flex-wrap: wrap;
        padding: 10px;
    }
    .navbar-menu-icon {
        display: block;
    }
    .navbar-links {
        display: none;
        flex-direction: column;
        width: 100%;
        background-color: white;
    }
    .navbar-links.active {
        display: flex;
    }
    .navbar-links button {
        width: 100%;
        text-align: left;
        padding: 10px 20px;
        margin: 0;
        border-top: 1px solid #333;
    }
    .navbar-links button:hover {
        background-color: #333;
    }
}

.navbar-links button {
    color: #fff;
    font-size: 18px;
    transition: background-color 0.3s, color 0.3s;
}

.navbar-links button:hover {
    background-color: #555;
}

.navbar-menu-icon .menu-icon span {
    background-color: black;
}

.navbar-menu-icon .menu-icon.open span {
    background-color: black;
}